<template>
  <div>
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/koa2022_program_v4.png' class='w-full' />
  </div>
</template>

<script>

export default {
  name: 'ProgramAtAGlance',
}
</script>


